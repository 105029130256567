.view-header {
  position: relative;
  width: 100%;
  order: 1;
  z-index: 10;
  @media (max-width: 1024px) {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }
  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
    padding: 20px 10px;
    @media (min-width: 1440px) {
      padding-left: 40px;
      padding-right: 40px;
    }
    .column {
      &--left {
        display: flex;
        align-items: center;
      }
    }
    .box-logo {
      flex-shrink: 0;
      position: relative;
      width: 100%;
      max-width: 250px;
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid #e5e5e5;
      @media (max-width: 1024px) {
        max-width: 200px;
        margin-right: 0;
        padding-right: 0;
        border-right: none;
      }
      &__link {
        display: block;
        width: 100%;
        img {
          display: block;
          width: 100%;
        }
      }
    }
    .nav-list {
      display: flex;
      flex-shrink: 0;
      @media (max-width: 1024px) {
        display: none;
      }
      & > li {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          background: #f1f1f1;
          transition: $default-transition;
          transform: scaleX(0);
          will-change: transform;
        }
        & > a {
          position: relative;
          display: block;
          padding: 20px 15px;
          font-weight: 700;
          letter-spacing: 1px;
          text-transform: uppercase;
          @media (max-width: 1366px) {
            padding: 20px 10px;
            font-size: 13px;
            letter-spacing: unset;
          }
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 2px;
            background: $bold-black;
            transform: translateY(100%) scaleY(0);
            transition: $default-transition;
            will-change: transform;
          }
          &:hover {
            color: $bold-black;
            &::after {
              transform: translateY(0) scaleY(1);
              transition-delay: $default-delay;
            }
          }
        }
        &:hover {
          &::before {
            transform: scaleX(1);
          }
        }
        &.has-dropdown {
          &::after {
            content: '';
            position: absolute;
            top: calc(50% - 6px);
            right: 15px;
            display: block;
            width: 10px;
            height: 10px;
            border: 2px solid transparent;
            border-right-color: #ccc;
            border-bottom-color: #ccc;
            transform: rotate(45deg);
            transition: $slow-transition;
          }
          & > a {
            padding-right: 40px;
            @media (max-width: 1366px) {
              padding-right: 35px;
            }
            &::after {
              display: none;
            }
          }
          .sub-menu {
            position: absolute;
            top: calc(100% - 2px);
            right: 0;
            width: 100%;
            min-width: 200px;
            padding: 15px;
            border-top: 2px solid $bold-black;
            background: #fff;
            box-shadow: 0 10px 30px rgba(0, 0, 0, .1);
            transition: $slow-transition;
            transform: translateY(20px);
            opacity: 0;
            visibility: hidden;
            &::before {
              content: '';
              position: absolute;
              top: 0;
              right: 16px;
              display: block;
              width: 10px;
              height: 10px;
              background: linear-gradient(-45deg, transparent 50%, $bold-black 50%);
              transform: rotate(45deg) translate(-25%, -50%);
              will-change: transform;
              z-index: 1;
            }
            & > li {
              & > a {
                display: block;
                padding: 10px 0;
                font-size: 16px;
                &:hover {
                  transform: translateX(5px);
                }
              }
            }
          }
          &:hover {
            &::after {
              border-right-color: $bold-black;
              border-bottom-color: $bold-black;
            }
            .sub-menu {
              opacity: 1;
              visibility: visible;
              transform: translateY(0);
            }
          }
        }
      }
    }
    .box-search {
      position: relative;
      @media (max-width: 1024px) {
        display: none;
      }
      &__panel {
        position: absolute;
        top: calc(100% + 20px);
        right: 0;
        width: 280px;
        border-top: 2px solid $bold-black;
        background: #fff;
        box-shadow: 0 10px 30px rgba(0, 0, 0, .1);
        visibility: hidden;
        opacity: 0;
        transform: translateY(20px);
        transition: $slow-transition;
        z-index: 3;
        &.is-active {
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
        }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 15px;
          display: block;
          width: 10px;
          height: 10px;
          background: linear-gradient(-45deg, transparent 50%, $bold-black 50%);
          transform: rotate(45deg) translate(-25%, -50%);
          will-change: transform;
          z-index: 1;
        }
        .form-search {
          .form__field {
            .form-control {
              min-width: 200px;
            }
          }
        }
      }
    }
    .toggle-mobile-nav {
      @media (min-width: 1025px) {
        display: none;
      }
    }
  }
}
.mobile-nav {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: $mobile-nav-width;
  height: calc(100vh + 0px);
  background: #efefef;
  box-shadow: 0 0 30px rgba(0, 0, 0, .15);
  transform: translateX($mobile-nav-width);
  transition: $slow-transition;
  overflow-y: auto;
  z-index: -1;
  visibility: hidden;
  @media (min-width: 1025px) {
    display: none;
  }
  &.is-active {
    @media (max-width: 1024px) {
      visibility: visible;
      transform: translateX(0);
      z-index: 11;
    }
  }
  &-list {
    & > li {
      border-bottom: 1px solid #ccc;
      &:first-child {
        border-top: 1px solid #ccc;
      }
      &.has-dropdown {
        position: relative;
        & > a {
          position: relative;
          border-bottom: 1px solid transparent;
          transition: unset;
          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 15px;
            display: block;
            width: 15px;
            height: 2px;
            background: #ccc;
            transition: $default-transition;
          }
          &::after {
            transform: rotate(90deg);
          }
        }
        & > .sub-menu {
          display: none;
        }
        &.is-active {
          & > a {
            font-weight: 700;
            border-bottom-color: #ccc;
            &::before,
            &::after {
              background: $black;
            }
            &::after {
              transform: rotate(0deg);
              opacity: 0;
            }
          }
          & > .sub-menu {
            display: block;
          }
        }
      }
      & > a {
        display: block;
        height: 50px;
        padding: 0 15px;
        font-size: 14px;
        line-height: 50px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        text-align: right;
        &:hover {
          color: inherit;
        }
      }
      & > .sub-menu {
        padding: 15px 0;
        & > li {
          & > a {
            display: block;
            padding: 15px 30px;
            font-size: 16px;
            text-align: right;
            &:hover {
              color: inherit;
            }
          }
        }
      }
    }
  }
}