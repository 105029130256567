.articles-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px 10px 0;
  @media (max-width: 1024px) {
    padding: 15px 5px 0;
  }
  &__item {
    display: flex;
    flex-basis: 50%;
    padding: 10px;
    @media (max-width: 1024px) {
      padding: 5px;
    }
    @media (max-width: 499px) {
      flex-basis: 100%;
    }
    .box-article {
      display: flex;
      flex-grow: 1;
      align-items: flex-start;
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, .1);
      transition: $slow-transition;
      @media (max-width: 1024px) {
        flex-direction: column;
        padding-bottom: 30px;
      }
      &__img {
        flex-shrink: 0;
        display: block;
        width: 200px;
        height: 200px;
        overflow: hidden;
        @media (max-width: 1024px) {
          width: 100%;
          margin: 0 auto;
        }
        @media (min-width: 500px) and (max-width: 1024px) {
          height: calc(100vw / 2 - 70px);
        }
        @media (max-width: 499px) {
          height: calc(100vw / 1.5 - 40px);
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transform-origin: center;
          transition: all .75s ease-in-out;
          will-change: transform;
        }
        &:hover {
          img {
            @media (min-width: 1025px) {
              transform: scale(1.1);
            }
          }
        }
      }
      &__content {
        width: 100%;
        padding-left: 10px;
        @media (max-width: 1024px) {
          padding-left: 0;
        }
        &__info {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding-top: 5px;
          padding-bottom: 10px;
          @media (max-width: 1024px) {
            flex-direction: column;
          }
          .title {
            @media (max-width: 1024px) {
              order: 2;
            }
            &__link {
              display: inline-block;
              padding-bottom: 2px;
              font-family: $main-font;
              font-size: 21px;
              font-weight: 700;
            }
          }
          .date {
            flex-shrink: 0;
            padding-left: 10px;
            color: #ccc;
            transition: $slow-transition;
            @media (max-width: 1024px) {
              order: 1;
              padding: 5px 0;
              color: #999;
            }
          }
        }
        .description {
          font-weight: 400;
          color: $black-light;
          line-height: 1.5;
          transition: $default-transition;
          @media (max-width: 1024px) {
            font-size: 14px;
            font-weight: 400;
            color: $black-light;
          }
          p {
            font-weight: 400;
            color: $black-light;
            line-height: 1.5;
            transition: $default-transition;
            @media (max-width: 1024px) {
              font-size: 14px;
              font-weight: 400;
              color: $black-light;
            }
          }
          .read-more {
            margin-top: 5px;
            &__link {
              display: inline-block;
              border-bottom: 1px solid $primary-color;
              color: $primary-color;
              &:hover {
                border-bottom-color: transparent;
              }
            }
          }
        }
      }
    }
    &:hover {
      .box-article {
        box-shadow: 0 10px 30px rgba(0, 0, 0, .1);
        &__content {
          &__info {
            .date {
              color: #999;
            }
          }
          .description {
            color: $black;
            p {
              color: $black;
            }
          }
        }
      }
    }
  }
}