.view-footer {
  position: relative;
  width: 100%;
  padding: 30px 10px;
  order: 3;
  background: #111;
  color: #fff;
  @media (min-width: 1440px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 767px) {
    padding: 30px 10px;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      display: block;
    }
    &__left {
      .box-logo {
        @media (max-width: 767px) {
          display: none;
        }
        a {
          display: block;
          max-width: 300px;
          img {
            display: block;
            width: 100%;
          }
        }
      }
      .social-links {
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
          padding-bottom: 30px;
          justify-content: center;
        }
        &__item {
          &__link {
            padding: 15px;
            font-size: 24px;
            color: #ccc;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
    address {
      display: block;
      margin: 0;
      font-style: normal;
      text-align: right;
      @media (max-width: 767px) {
        text-align: left;
        font-size: 14px;
      }
      .title {
        padding-bottom: 10px;
      }
      a {
        border-bottom: 1px solid #fff;
        color: #fff;
        cursor: pointer;
        @media (max-width: 767px) {
          font-size: 14px;
        }
        &:hover {
          border-bottom-color: transparent;
        }
      }
      p {
        padding: 0;
        font-weight: 300;
        font-size: 15px;
        line-height:  2;
        color: #ccc;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
  .box-copyright {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, .1);
    font-size: 15px;
    line-height: 2;
    font-weight: 400;
    color: #ccc;
    @media (max-width: 767px) {
      display: block;
      font-size: 14px;
      line-height: 2;
      text-align: center;
      letter-spacing: unset;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 15px;
      line-height: 2;
      font-weight: 400;
      color: #ccc;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    a {
      border-bottom: 1px solid #fff;
      font-size: 15px;
      line-height: 2;
      font-weight: 400;
      color: #fff;
      cursor: pointer;
      @media (max-width: 767px) {
        font-size: 14px;
      }
      &:hover {
        border-bottom-color: transparent;
      }
    }
    .default {
      & > span {
        @media (max-width: 767px) {
          display: block;
          margin: 10px 0 0;
        }
        span {
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
    }
    .powered {
      @media (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }
}