[class*="pagination"] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px 0 20px;
  .page-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 5px;
    padding: 0;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, .2);
    background: transparent;
    font-size: 18px;
    line-height: 1;
    &:hover {
      border-color: $black;
      background: $black;
      color: #fff;
    }
    &.prev,
    &.next {
      position: relative;
      color: transparent;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color: $black;
      }
    }
    &.prev {
      &::before {
        content: '\f053';
        transform: translateX(-1px);
      }
    }
    &.next {
      &::before {
        content: '\f054';
        transform: translateX(2px);
      }
    }
    &.prev,
    &.next {
      &:hover {
        &::before {
          color: #fff;
        }
      }
    }
    &.current {
      pointer-events: none;
      background: $black;
      color: #fff;
    }
    &.dots {
      pointer-events: none;
      border: none;
      background: none;
      color: #ccc;
    }
  }
}