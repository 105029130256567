/*
BUTTONS GEOMETRY
___
# class .button in (_parts/buttons.scss)
*/

$button-gap: 1rem;
$button-height: 50px;
$button-border-radius: 0;
$button-border: #a8a8a8;

%buttonsGeometry {
  position: relative;
  display: inline-block;
  height: $button-height;
  padding: 0 ($button-gap * 3);
  background-color: transparent;
  border: 1px solid $button-border;
  border-radius: $button-border-radius;
  font-weight: 700;
  line-height: $button-height;
  text-decoration: none;
  text-transform: uppercase;
  transition: $slow-transition;
  color: $black;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  transform-origin: center;
}