/*
RESET STYLES
 */

html {
  width: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  font-family: $base-font; // (_main/fonts.scss)
  line-height: $base-line-height; // (_main/view-text.scss)
  font-size: $base-md-font-size; // (_main/view-text.scss)
  @media (min-width: 320px) and (max-width: 1024px) {
    font-size: $base-sm-font-size; // (_main/view-text.scss)
  }
  &.open-mobile-nav {
    @media(max-width: 1024px) {
      max-height: calc(100vh + 0px);
      overflow: hidden;
    }
  }
}

body {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: $base-bg; // (_main/main-colors.scss)
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  &.is-load {
    background: $body-load-bg; // (_main/main-colors.scss)
  }
  &.open-mobile-nav {
    .view-wrapper {
      @media(max-width: 1024px) {
        position: relative;
        transform: translateX(-$mobile-nav-width);
      }
    }
  }
  &::-webkit-scrollbar {
    width: 2px;
    &-thumb {
      background: $black;
    }
  }
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block;
}

blockquote, q {
  quotes: none;
  &::before,
  &::after {
    content: '';
  }
}

table {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-width: 0;
}

td {
  margin: 0;
  padding: 0;
}

form {
  margin: 0;
  padding: 0;
}

input, textarea {
  border-radius: 0;
  font-family: $base-font;  // (_main/fonts.scss)
  color: $black;  // (_main/default-colors.scss)
  outline: none;
  -webkit-appearance: $webkit-appearance;  // (_main/form.scss)
  box-shadow: none;
  @include placeholder($placeholder-color, true);  // mixins in (_functions/placeholder.scss), variables in (_main/form.scss)
  @include placeholder-on-focus(); // (_functions/placeholder.scss)
}

input[type="button"], input[type="submit"], button {
  cursor: pointer;
}

input[type=submit], input[type=button], button {
  -webkit-appearance: $webkit-appearance; // (_main/form.scss)
  outline: none;
}

div, span {
  outline: none;
}

* {
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 10px auto 10px auto;
}

.alignright {
  float: right;
  margin: 10px 0 10px 10px;
}

.alignleft {
  float: left;
  margin: 10px 10px 10px 0;
}

.aligncenter {
  display: block;
  margin: 10px auto 10px auto;
}

a img.alignright {
  float: right;
  margin: 10px 0 10px 10px;
}

a img.alignnone {
  margin: 10px 10px 10px 0;
}

a img.alignleft {
  float: left;
  margin: 10px 10px 10px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

.ui-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #ccc;
  font-size: 20px;
  color: #ccc;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #f1f1f1;
    transform: scale(1);
    transition: $slow-transition;
  }
  i {
    position: relative;
    z-index: 2;
  }
  &:hover,
  &.is-active {
    color: $black;
    &::before {
      transform: scale(0);
    }
  }
}
.is-disabled {
  opacity: .3;
  pointer-events: none;
}
.cbalink {
  display: none;
}



pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

progress {
  vertical-align: baseline;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

dfn, cite, em, i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark, ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
  clear: both;
}

.widget {
  margin: 0 0 1.5em;
}

.widget select {
  max-width: 100%;
}

.comment-content a {
  word-wrap: break-word;
}

.bypostauthor {
  display: block;
}

.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
  display: none;
}

.infinity-end.neverending .site-footer {
  display: block;
}

.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

embed,
iframe,
object {
  max-width: 100%;
}

.custom-logo-link {
  display: inline-block;
}

.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}
