/*
VIEW-WRAPPER
 */

// ON LOAD PAGE
.is-load .view-wrapper {
  visibility: hidden;
  max-height: calc(100vh + 0px);
}
.view-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: 320px;
  min-height: calc(100vh + 0px);
  padding: 0;
  transition: $slow-transition;
  overflow: hidden;
}
.container {
  position: relative;
  display: block;
  min-width: 320px;
  max-width: $container-width;
  min-height: 100%;
  margin: 0 auto;
  padding: 0;
}