/*
FONTS LOOP
 */

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab-Thin.eot');
  src: url('../fonts/RobotoSlab-Thin.eot?#iefix') format("embedded-opentype"),
  url('../fonts/RobotoSlab-Thin.woff') format("woff");
  font-weight: 200;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab-Light.eot');
  src: url('../fonts/RobotoSlab-Light.eot?#iefix') format("embedded-opentype"),
  url('../fonts/RobotoSlab-Light.woff') format("woff");
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab-Regular.eot');
  src: url('../fonts/RobotoSlab-Regular.eot?#iefix') format("embedded-opentype"),
  url('../fonts/RobotoSlab-Regular.woff') format("woff");
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab-Bold.eot');
  src: url('../fonts/RobotoSlab-Bold.eot?#iefix') format("embedded-opentype"),
  url('../fonts/RobotoSlab-Bold.woff') format("woff");
  font-weight: 700;
}

@font-face {
  font-family: 'PTSans';
  src: url('../fonts/PTSansRegular.eot');
  src: url('../fonts/PTSansRegular.eot?#iefix') format("embedded-opentype"),
  url('../fonts/PTSansRegular.woff') format("woff");
  font-weight: 400;
}

@font-face {
  font-family: 'PTSans';
  src: url('../fonts/PTSansBold.eot');
  src: url('../fonts/PTSansBold.eot?#iefix') format("embedded-opentype"),
  url('../fonts/PTSansBold.woff') format("woff");
  font-weight: 700;
}