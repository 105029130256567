.box-comments {
  padding: 50px 0;
  background: #f1f1f1;
  &__title {
    display: block;
    margin-bottom: 30px;
    font-family: $main-font;
    font-size: 18px;
    font-weight: 700;
  }
  .comment-respond {
    display: block;
    margin-bottom: 50px;
    padding: 30px;
    border: 1px solid rgba(0, 0, 0, .1);
    background: #fff;
    h3 {
      display: block;
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 18px;
      font-weight: 700;
      #cancel-comment-reply-link {
        margin-top: 10px;
        display: block;
        font-family: $base-font;
        color: #cc0000;
      }
    }
    .form--add-comment {
      p {
        padding-top: 0;
      }
      .form {
        &__field {
          margin-bottom: 20px;
        }
      }
    }
  }
  .comments-wrapper {
    &--has-comments {
      margin-top: 40px;
    }
    .box-empty {
      margin-bottom: 30px;
      padding: 30px;
      border: 1px solid rgba(0, 0, 0, .1);
      background: #fff;
      text-align: center;
    }
    .comments-list {
      display: block;
      li {
        &.depth-1 {
          position: relative;
          margin: 10px 0;
          padding: 30px 30px 10px;
          border: 1px solid rgba(0, 0, 0, .1);
          background: #fff;
          @media (max-width: 1024px) {
            padding: 20px 20px 10px;
          }
          .toggle-replies {
            display: block;
            width: 100%;
            border-top: 1px solid #e5e5e5;
            padding: 10px 0;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
            transition: $default-transition;
            &:hover {
              background: #e5e5e5;
            }
          }
          & > .children {
            display: none;
            &.is-active {
              display: block;
            }
          }
          .children {
            margin: 20px 0;
            padding: 20px 20px 10px;
            border: 1px solid rgba(0, 0, 0, .1);
            @media (max-width: 1024px) {
              padding: 20px 10px 10px;
            }
            li {
              margin-bottom: 20px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
        .comment-body {
          .comment-meta {
            display: flex;
            flex-direction: column;
            .comment-author {
              order: 2;
              margin: 10px 0;
              font-family: $main-font;
              font-size: 18px;
              line-height: 1.5;
              font-weight: 400;
              color: #0e0e0e;
            }
            .comment-metadata {
              order: 1;
              & > a {
                margin-bottom: 10px;
                font-size: 13px;
                color: #999;
              }
              .edit-link {
                position: absolute;
                top: 15px;
                right: 15px;
                display: block;
                .comment-edit-link {
                  font-size: 0;
                  color: transparent;
                  &::before {
                    content: '\f303';
                    font-family: 'Font Awesome 5 Free';
                    font-size: 16px;
                    font-weight: 900;
                    color: #ccc;
                    transition: $default-transition;
                  }
                  &:hover {
                    &::before {
                      color: $black-light;
                    }
                  }
                }
              }
            }
          }
          .comment-content {
            font-size: 16px;
            line-height: 1.5;
            text-align: left;
            color: #424242;
            @media (max-width: 1024px) {
              font-size: 14px;
            }
            p {
              padding: 0;
              font-size: 16px;
              line-height: 1.5;
              text-align: left;
              color: #424242;
              @media (max-width: 1024px) {
                font-size: 14px;
              }
            }
            .hide-text {
              display: none;
            }
            .clamp {
              display: inline;
            }
            .read-more-link {
              border-bottom: 1px solid coral;
              color: coral;
              &:hover {
                border-bottom-color: transparent;
              }
            }
            &.is-expand {
              .hide-text {
                display: inline;
              }
              .clamp {
                display: none;
              }
            }
          }
          .reply {
            padding: 20px 0 10px;
            .comment-reply-link {
              font-size: 13px;
              color: $black-light;
              &:hover {
                color: $black;
              }
            }
          }
        }
      }
    }
  }
}