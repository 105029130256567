.view-section {
  position: relative;
  width: 100%;
  order: 2;
  flex-grow: 1;
}
.home-page {
  .box-main-slider {
    position: relative;
    background: #000;
    z-index: 1;
    @media (max-width: 767px) {
      display: none;
    }
    .slick-prev {
      right: 70px;
      bottom: 10px;
    }
    .slick-next {
      right: 30px;
      bottom: 10px;
    }
    .scroll-down {
      position: absolute;
      bottom: 20px;
      left: 50%;
      display: block;
      width: 30px;
      height: 50px;
      border-radius: 20px;
      border: 2px solid #fff;
      z-index: 3;
      transform: translateX(-50%);
      &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: calc(50% - 1px);
        display: block;
        width: 2px;
        height: 10px;
        background: #fff;
        transition: $default-transition;
      }
      span {
        position: absolute;
        top: 50%;
        display: block;
        font-weight: 700;
        color: #fff;
        opacity: 0;
        visibility: hidden;
        transition: all .35s ease;
        transform-origin: center;
        &:first-child {
          left: calc(50% - 30px);
          transform: translate(-150%, -50%) scale(1.5);
        }
        &:last-child {
          right: calc(50% - 30px);
          transform: translate(150%, -50%) scale(1.5);
        }
      }
      &:hover {
        background: #fff;
        box-shadow: 0 10px 30px rgba(0, 0, 0, .2);
        &::before {
          background: #000;
        }
        span {
          opacity: 1;
          visibility: visible;
          &:first-child {
            transform: translate(-100%, -50%) scale(1);
          }
          &:last-child {
            transform: translate(100%, -50%) scale(1);
          }
        }
      }
    }
    .main-slide {
      position: relative;
      &__img {
        display: block;
        width: 100%;
        img {
          display: block;
          width: 100%;
          height: 100%;
          height: calc(100vh - 100px);
          object-fit: cover;
          object-position: center;
        }
        &.reverse {
          img {
            transform: scaleX(-1);
          }
        }
      }
      &__panel {
        position: absolute;
        top: 0;
        right: 0;
        width: 320px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 50px 30px;
        background: rgba(0, 0, 0, .75);
        color: #fff;
        z-index: 1;
        @media (min-width: 768px) {
          height: calc(100vh - 100px);
        }
        &__content {
          .title {
            margin-bottom: 15px;
            &__link {
              display: inline-block;
              font-family: $main-font;
              font-size: 24px;
              line-height: 1.5;
              color: #fff;
              &:hover {
                color: #ccc;
              }
            }
          }
          .description {
            margin-bottom: 15px;
            font-family: $base-font;
            font-size: 15px;
            line-height: 1.5;
            font-weight: 300;
            color: #e5e5e5;
            p {
              margin: 0;
              padding: 0;
              font-family: $base-font;
              font-size: 15px;
              line-height: 1.5;
              font-weight: 300;
              color: #e5e5e5;
              white-space: normal;
            }
            .read-more {
              margin-top: 5px;
              &__link {
                display: inline-block;
                border-bottom: 1px solid #fff;
                letter-spacing: .5px;
                color: #fff;
                &:hover {
                  border-bottom-color: transparent;
                }
              }
            }
          }
          .date {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
  }
  .view-news {
    padding: 50px 10px;
    @media (max-width: 1024px) {
      padding: 30px 5px;
    }
  }
  .box-category-link {
    padding: 30px 0 0;
  }
  .view-gallery {
    padding: 50px 0;
    background: url('../img/img_gallery.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1024px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .gallery-slider {
      margin: 50px 0 20px;
      @media (max-width: 1024px) {
        margin: 30px 0 0;
      }
      .slick-prev {
        left: 10px;
        top: 50%;
      }
      .slick-next {
        right: 10px;
        top: 50%;
      }
      .slick-slide {
        padding: 0 5px;
      }
      &__link {
        display: block;
        width: 100%;
        overflow: hidden;
        @media (min-width: $full-min-width) {
          height: calc((100vw - 80px) / 6);
        }
        @media (min-width: 1025px) and (max-width: $container-width) {
          height: calc((100vw - 60px) / 5);
        }
        @media (min-width: 769px) and (max-width: 1024px) {
          height: calc((100vw - 40px) / 4);
        }
        @media (min-width: 600px) and (max-width: 768px) {
          height: calc((100vw - 20px) / 3);
        }
        @media (max-width: 599px) {
          height: calc((100vw - 10px) / 2);
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transform-origin: center;
          transition: all .75s ease-in-out;
          will-change: transform;
        }
        &:hover {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
  .view-about {
    padding: 50px 10px;
    @media (max-width: 1024px) {
      padding: 30px 10px;
    }
    &__text {
      max-width: 600px;
      padding: 10px 10px 30px;
      margin: 0 auto;
      font-family: $main-font;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      @media (max-width: 1024px) {
        font-size: 14px;
      }
      p {
        margin: 0;
        padding: 0;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
        @media (max-width: 1024px) {
          font-size: 14px;
        }
      }
    }
    &__img {
      max-width: 100px;
      margin: auto;
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .view-subscribe {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 500px;
    padding: 50px 10px;
    background: url('../img/img_subscribe.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    h1 {
      padding-top: 0;
    }
    .form {
      width: 100%;
      max-width: 500px;
      margin: 50px auto 0;
      &__field {
        margin-bottom: 30px;
        text-align: center;
        .form-control {
          height: 50px;
          line-height: 50px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, .1);
        }
      }
    }
  }
}
[class*="page-"] {
  padding: 30px 0;
}
.page-gallery {
  .gallery-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 50px 10px 0;
    &__item {
      position: relative;
      padding: 10px;
      @media (min-width: $container-width) {
        flex-basis: 25%;
      }
      @media (min-width: 1025px) and (max-width: 1439px) {
        flex-basis: 33.33%;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        flex-basis: 50%;
      }
      @media (max-width: 767px) {
        flex-basis: 100%;
        padding: 5px 0;
      }
      &:hover {
        @media (min-width: 1025px) {
          .gallery-list__item__link {
            box-shadow: 0 10px 30px rgba(0, 0, 0, .1);
            &::before,
            &::after {
              transition-delay: 0s;
              transform: translateX(0) scaleX(1);
            }
            img {
              filter: blur(10px);
              transform: scale(1.05);
            }
            .title {
              opacity: 1;
              transform: translateY(0);
              transition-delay: 1s;
            }
          }
          .hover-buttons {
            &__link {
              &:first-child {
                opacity: 1;
                transform: translateX(0);
                transition: transform 1s ease-in-out 1.5s,
                            opacity 1s ease-in-out 1s,
                            background .35s ease-in-out 0s,
                            box-shadow .35s ease-in-out 0s;
              }
              &:last-child {
                transform: scale(1);
                transition: transform .5s ease-in-out 1s,
                            background .35s ease-in-out 0s,
                            box-shadow .35s ease-in-out 0s;
              }
            }
          }
        }
      }
      &__link {
        position: relative;
        display: block;
        width: 100%;
        background: $black;
        box-shadow: 0 10px 30px transparent;
        overflow: hidden;
        transition: $slow-transition;
        @media (max-width: 1024px) {
          pointer-events: none;
        }
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 10px;
          display: block;
          width: calc(50% - 10px);
          height: calc(100% - 20px);
          background: #fff;
          transition: all 1s ease-in-out 1s;
          z-index: 1;
          @media (max-width: 1024px) {
            display: none;
          }
        }
        &::before {
          left: 10px;
          transform: translateX(-50%) scaleX(0);
        }
        &::after {
          right: 10px;
          transform: translateX(50%) scaleX(0);
        }
        img {
          display: block;
          width: 100%;
          object-fit: cover;
          object-position: center;
          transition: all 1s ease-in-out;
          transform-origin: center;
          @media (min-width: $container-width) {
            height: calc((1440px - 100px) / 4 * .8);
          }
          @media (min-width: 1025px) and (max-width: 1439px) {
            height: calc((100vw - 80px) / 3 * .8);
          }
          @media (min-width: 768px) and (max-width: 1024px) {
            height: calc((100vw - 60px) / 2 * .8);
          }
          @media (max-width: 767px) {
            height: calc((100vw - 40px) * .8);
          }
        }
        .title {
          position: absolute;
          top: 20px;
          left: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          padding: 50px;
          font-size: 21px;
          line-height: 1.3;
          font-weight: 500;
          text-align: center;
          color: $black;
          z-index: 2;
          opacity: 0;
          overflow: hidden;
          transition: all 1s ease-in-out;
          transform: translateY(10px);
          overflow: auto;
          @media (max-width: 1024px) {
            display: none;
          }
        }
      }
      .hover-buttons {
        position: absolute;
        bottom: 30px;
        right: 30px;
        display: flex;
        align-items: center;
        z-index: 2;
        @media (min-width: 768px) and (max-width: 1024px) {
          bottom: 20px;
          right: 20px;
        }
        @media (max-width: 767px) {
          bottom: 15px;
          right: 10px;
        }
        &__link {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          margin-right: 10px;
          border-radius: 50%;
          background: #e9e9e9;
          font-size: 21px;
          color: $black;
          transition: box-shadow .35s ease-in-out;
          @media (min-width: 1025px) {
            box-shadow: 0 0 20px rgba(0, 0, 0, .2);
          }
          @media (max-width: 1024px) {
            background: #fff;
          }
          @media (max-width: 767px) {
            margin-right: 0;
          }
          &:first-child {
            @media (min-width: 1025px) {
              opacity: 0;
              transform: translateX(50px);
              transition: transform 1s ease-in-out 0s,
                          opacity 1s ease-in-out .5s,
                          background .35s ease-in-out 0s,
                          box-shadow .35s ease-in-out 0s;
            }
          }
          &:last-child {
            margin-right: 0;
            z-index: 1;
            @media (min-width: 1025px) {
              transform: scale(0);
              transition: transform .5s ease-in-out 1s,
                          background .35s ease-in-out 0s,
                          box-shadow .35s ease-in-out 0s;
            }
            @media (max-width: 767px) {
              display: none;
            }
          }
          &:hover {
            @media (min-width: 1025px) {
              box-shadow: 0 0 20px rgba(0, 0, 0, .3);
              background: #e0e0e0;
            }
            @media (max-width: 1024px) {
              background: #efefef;
            }
          }
        }
      }
    }
  }
}
.post-container {
  .container {
    padding: 0 10px;
  }
  &__title {
    margin-bottom: 50px;
    font-size: 2rem;
    line-height: 1.3;
    text-transform: uppercase;
    text-align: center;
  }
  &__date {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    color: #999;
    span {
      padding-left: 5px;
      font-family: $main-font;
      font-weight: 500;
    }
  }
  &__img {
    margin-bottom: 20px;
    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  &__text {
    padding-bottom: 50px;
    color: $black;
    &.view-text {
      font-family: $base-font;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;
      color: $black;
      h1 {
        &::before {
          content: unset;
        }
      }
      h1, h2, h3, h4, h5, h6 {
        margin: 10px 0;
        padding: 0;
        line-height: 1.3;
        text-transform: unset;
      }
      p {
        padding: 5px 0;
        font-family: $base-font;
        font-size: 18px;
        line-height: 1.5;
        font-weight: 400;
        text-transform: unset;
      }
      b, strong {
        font-weight: 700;
      }
      a {
        display: inline-block;
        color: #0000dd;
        transition: $default-transition;
      }
      ul, ol {
        margin: 0 0 0 40px;
        padding: 10px 0;
        li {
          line-height: 1.5;
        }
      }
      ul {
        li {
          list-style-type: disc;
        }
      }
      ol {
        li {
          list-style-type: decimal;
        }
      }
      img {
        &:not(.alignleft):not(.aligncenter):not(.alignright) {
          margin: 10px;
        }
      }
      hr {
        border: none;
        border-bottom: 1px solid #ccc;
      }
    }
  }
}
.page-post {
  padding: 30px 0 0;
}
.page-contacts {
  padding-bottom: 0;
  .container {
    padding: 0 10px;
  }
  .social-links {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    &__item {
      &__link {
        display: block;
        padding: 30px;
        font-size: 40px;
        @media (max-width: 767px) {
          padding: 15px;
          font-size: 24px;
        }
      }
    }
  }
  .contacts {
    &-text {
      padding-top: 50px;
      &.view-text {
        font-family: $base-font;
        font-size: 18px;
        line-height: 1.5;
        p {
          font-family: $base-font;
          font-size: 18px;
          line-height: 1.5;
        }
        a {
          border-bottom: 1px solid $black;
          text-decoration: none;
          transition: $default-transition;
          &:hover {
            border-bottom-color: transparent;
          }
        }
      }
    }
    &-feedback {
      padding: 50px 0;
      background: #f1f1f1;
    }
  }
}
.page-search {
  .box-results {
    display: flex;
    margin: 50px 20px 10px;
    padding: 20px;
    border-left: 3px solid $black;
    background: #efefef;
    font-size: 18px;
    @media (max-width: 1024px) {
      margin-right: 10px;
      margin-left: 10px;
    }
    @media (max-width: 767px) {
      display: block;
    }
    div {
      line-height: 1.3;
      &:first-child {
        flex-shrink: 0;
        padding-right: 10px;
        @media (max-width: 767px) {
          padding: 0 0 10px;
        }
      }
    }
  }
}
.box-empty {
  padding: 200px 0;
  text-align: center;
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 100px 0;
  }
  @media (max-width: 767px) {
    padding: 50px 0;
  }
  &__title {
    margin-bottom: 50px;
    font-family: $main-font;
    font-size: 36px;
    line-height: 1.5;
    font-weight: 700;
  }
  &__subtitle {
    margin-bottom: 50px;
    font-size: 18px;
    line-height: 1.5;
  }
  &__button {
    .button {
      letter-spacing: 2px;
    }
  }
}
.page-about {
  padding-bottom: 0;
}
.faces {
  padding: 50px 0 0;
  background: #f1f1f1;
  .container {
    padding: 0 10px;
  }
  .box-faces {
    display: block;
    margin-bottom: 50px;
    padding: 50px 0;
    border: 1px solid #e5e5e5;
    background: #fff;
    &__title {
      position: relative;
      padding: $default-padding-h1;
      margin-bottom: 20px;
      font-family: $default-font-family-h1;
      font-size: $default-font-size-h1;
      line-height: $default-line-height-h1;
      font-weight: $default-font-weight-h1;
      color: $default-color-h1;
      letter-spacing: 1px;
      text-transform: uppercase;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        display: block;
        width: 50px;
        height: 4px;
        border-radius: 50px;
        background: #ccc;
        transform: translate(-50%, 100%);
      }
    }
  }
  &-list {
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: 33.33%;
      padding: 30px;
      @media (min-width: 768px) and (max-width: 1024px) {
        flex-basis: 50%;
      }
      @media (max-width: 767px) {
        flex-basis: 100%;
        padding: 30px 10px;
      }
      &__img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        overflow: hidden;
        @media (max-width: 1024px) {
          width: 150px;
          height: 150px;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      &__name {
        margin-top: 20px;
        font-size: 21px;
        line-height: 1.5;
        font-weight: 600;
        text-align: center;
        @media (max-width: 1024px) {
          font-size: 18px;
        }
      }
      &__desc {
        margin-top: 10px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
        text-align: center;
        color: $black;
        p {
          margin: 0;
          padding: 0;
          font-size: 16px;
          line-height: 1.5;
          font-weight: 400;
          text-align: center;
          color: $black;
        }
      }
      &.faces-list__item--general {
        flex-basis: 100%;
        @media (min-width: 768px) {
          .faces-list__item__img {
            width: 280px;
            height: 280px;
          }
          .faces-list__item__name {
            font-size: 24px;
          }
          .faces-list__item__desc {
            font-size: 18px;
            p {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}