.form {
  .box-message {
    padding-bottom: 10px;
    font-weight: 700;
    text-align: center;
    &.sent {
      &-error {
        color: #dd0000;
      }
      &-success {
        color: forestgreen;
      }
    }
  }
  .control-holder {
    &:last-child {
      .form__field {
        margin-bottom: 0;
      }
    }
    &.has-error {
      .form__field {
        .form-control {
          border-color: #dd0000;
        }
        .box-error {
          padding-top: 10px;
          color: #dd0000;
        }
      }
    }
  }
  &__field {
    position: relative;
    &__label {
      display: block;
      margin-bottom: 10px;
      font-size: 16px;
      cursor: pointer;
      .required {
        color: #cc0000;
      }
    }
    .form-control {
      display: block;
      width: 100%;
      height: 40px;
      padding: 0 15px;
      border: 1px solid rgba(0, 0, 0, .1);
      background: #fff;
      font-size: 15px;
      line-height: 40px;
      color: rgba(0, 0, 0, .35);
      transition: $default-transition;
      &--short {
        max-width: 300px;
      }
      &--textarea {
        min-height: 100px;
        max-width: 767px;
      }
      &:hover,
      &:focus {
        border-color: rgba(0, 0, 0, .3);
        color: rgba(0, 0, 0, .65);
      }
    }
  }
  &--feedback {
    display: block;
    padding: 30px;
    border: 1px solid rgba(0, 0, 0, .1);
    background: #fff;
    .form {
      &__field {
        margin-bottom: 20px;
      }
    }
  }
}
.form-search {
  position: relative;
  display: block;
  padding: 30px 10px;
  z-index: 2;
  .form__field {
    position: relative;
    .form-control {
      width: 100%;
      padding-right: 55px;
      &:hover,
      &:focus {
        border-color: rgba(0, 0, 0, .1);
      }
    }
    .search-button {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border: none;
      background: $black;
      color: #fff;
      transition: $default-transition;
      &.is-disabled {
        opacity: 1;
        background: #ccc;
      }
    }
  }
}