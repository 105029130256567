.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: calc(100% + 10px);
  margin-left: -5px;
  @media (max-width: 374px) {
    width: 100%;
    margin: 0;
  }
  .gallery-item {
    padding: 5px;
    @media (max-width: 374px) {
      padding: 5px 0;
    }
    .gallery-icon {
      a {
        display: block;
        width: 100%;
        height: 200px;
        img {
          display: block;
          width: 100%;
          height: 100%;
          margin: 0!important;
          padding: 0;
          object-fit: cover;
          object-position: center;
          transition: $slow-transition;
          transform-origin: center;
        }
        &:hover {
          img {
            transform: scale(.98);
          }
        }
      }
    }
  }
  &.gallery-columns-1 {
    width: 100%;
    margin: 0;
    .gallery-item {
      padding: 5px 0;
    }
  }
  &-columns-1 .gallery-item {
    max-width: 100%;
    .gallery-icon {
      a {
        @media (min-width: 375px) {
          height: 300px;
        }
      }
    }
  }
  &-columns-2 .gallery-item {
    max-width: 50%;
    .gallery-icon {
      a {
        @media (min-width: 375px) {
          height: 300px;
        }
      }
    }
    @media (max-width: 374px) {
      max-width: 100%;
    }
  }
  &-columns-3 .gallery-item {
    max-width: calc(100% / 3);
  }
  &-columns-4 .gallery-item {
    max-width: calc(100% / 4);
  }
  &-columns-5 .gallery-item {
    max-width: calc(100% / 5);
  }
  &-columns-6 .gallery-item {
    max-width: calc(100% / 6);
  }
  &-columns-7 .gallery-item {
    max-width: calc(100% / 7);
  }
  &-columns-8 .gallery-item {
    max-width: calc(100% / 8);
  }
  &-columns-9 .gallery-item {
    max-width: calc(100% / 9);
  }
  &-columns-3,
  &-columns-4,
  &-columns-5,
  &-columns-6,
  &-columns-7,
  &-columns-8,
  &-columns-9 {
    .gallery-item {
      @media (min-width: 768px) and (max-width: 1024px) {
        max-width: calc(100% / 3);
      }
      @media (min-width: 375px) and (max-width: 767px) {
        max-width: 50%;
      }
      @media (max-width: 374px) {
        max-width: 100%;
      }
    }
  }
  &-columns-7,
  &-columns-8,
  &-columns-9 {
    .gallery-item {
      .gallery-icon {
        a {
          @media (min-width: 1025px) {
            height: 100px;
          }
        }
      }
    }
  }
}